import { Button, Modal, Paper, Stack, TextField } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import { FC, useState } from 'react';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export const AuthModal: FC<Props> = ({ open, handleClose }) => {
  const { user, signIn } = useAuth();

  const [personalNumber, setPersonalNumber] = useState<string>('');
  const [givenName, setGivenName] = useState<string>('');
  const [surName, setSurName] = useState<string>('');

  const handleSignIn = () => {
    signIn({ personalNumber, givenName, surName });
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Paper variant="outlined" sx={{ display: 'flex', flexDirection: 'column', p: 4, gap: 2 }}>
          <TextField
            onChange={e => setPersonalNumber(e.target.value.replace('-', ''))}
            value={personalNumber ?? user?.personalNumber}
            size="small"
            placeholder="PIN - YYYYMMDDXXXX"
          />
          <TextField
            onChange={e => setGivenName(e.target.value)}
            value={givenName ?? user?.givenName}
            size="small"
            placeholder="Firstname"
          />
          <TextField
            onChange={e => setSurName(e.target.value)}
            value={surName ?? user?.surName}
            size="small"
            placeholder="Lastname"
          />
          <Button onClick={handleSignIn} variant="contained">
            Sign in as mocked user
          </Button>
          <Button href={'/auth/signin'} variant="contained">
            Sign in with bankid
          </Button>
        </Paper>
      </Stack>
    </Modal>
  );
};
